body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.container {
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex-direction: column;
  /* Stack elements vertically */
  width: 100%;
  margin: 0 auto;
  /* Center horizontally */
  background-color: #ffffff;
}

.my-form {
  width: 80%;
  max-width: 100%;
  margin: 0 auto;
}

.table {
  flex-grow: 1;
}

main {
  width: 100%;
  padding: 20px;
  padding-left: 60px;
}

.sidebar {
  background: #000;
  position: absolute;
  float: right;
  color: #fff;
  /* height: 120vh; */
  width: auto;
  transition: all 0.5s;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 11px;
}

.logo {
  font-size: 30px;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}

.link:hover {
  background: lightskyblue;
  color: #000;
  transition: all 0.5s;
}


.header {
  background-color: #808080;

  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__title {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.header__profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.parent-label {
  float: left;
}

.parent-rigth {
  float: right;
}

.cuadro_princal {
  border-bottom: 2px solid #ccc;
  /* Borde en la parte inferior */
  padding-bottom: 5px;
  /* Espacio entre el título y el botón */
}

.titulo_cuadro {
  font-size: 20px;
  /* Tamaño del texto del título */
  font-weight: bold;
  /* Negrita */
  /* Espacio entre el título y otros elementos */
}

.error-message {
  color: red;
  /* Adjust color as needed */
}


.header__menu--open {
  display: block;
}

.header__menu-item {
  padding: 10px;
}

.header__title {
  font-size: 20px;
  font-weight: 600;
}