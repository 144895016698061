/*  */
.table-container {
    margin: 20px 0;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.btn-filter-clear {
    padding: 8px 15px;
    border: none;
    background-color: #6c757d;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.table-header {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 10px;
}

.btn {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-export {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    margin-left: 10px;
}

.btn-add {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #28a745;
    color: white;
    margin-left: 10px;
}

.btn-delete-selected {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #dc3545;
    color: white;
    margin-left: 10px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.table th {
    background-color: #f1f1f1;
}

.btn-edit, .btn-delete {
    background: none;
    border: none;
    cursor: pointer;
}

.table-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

@media screen and (max-width:767px) {
    .table-container {
        margin: 0px;
        padding: 0px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .table-header {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 10px;
    }
}